<template>
  <el-footer>
  </el-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
